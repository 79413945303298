<template>
    <div class="bg-white">
        <app-modal-order @selectOrder="selectOrder" :endPoint="endPoint" :idEditing="idEditing" ref="modal_list_order"></app-modal-order>
        <app-modal-confirm
            ref="entry_modal_delete"
            :modal-header-title="$t('common.delete confirm title')"
            @confirmed="deleteEntry()"
        >
            <template v-slot:body>
                <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
            </template>
        </app-modal-confirm>

        <validation-observer
            ref="form_create_entry"
            v-slot="{ passes, valid, validated }"
        >
            <form class="from-create form-horizontal" v-on:submit.prevent="passes(createOrUpdate)">
                <div class="card card-body">
                    <div v-if="idEditing">
                        <div class="form-group row">
                        <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
                        <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8"> {{ entry.id }}</div>
                        </div>
                    </div>
                    <validation-provider
                        name="status_enabled"
                        rules="required"
                        v-slot="{ errors, invalid, validated }"
                    >
                        <div class="form-group row">
                            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.status") }} <span class="app-require">＊</span></label>
                            <div class="col-xl-5 col-lg-6 col-sm-9 align-self-center">
                                <app-radio v-for="(item, index) in RECEIPTS_CONSTANT.STATUS"
                                    :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                                    :val="item.id" v-model="entry.status_enabled"
                                />
                                <label v-if="invalid && validated" class="error">
                                    {{ errors[0] }}
                                </label>
                            </div>
                        </div>
                    </validation-provider>
                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("receipts.mansion") }} <span class="app-require">＊</span></label>
                      <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-select
                          name="apartment_id"
                          rules="required"
                          :options-data="meta.apartments"
                          v-model="entry.apartment_id"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("receipts.receipt_number") }} <span class="app-require">＊</span></label>
                      <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-input
                            name="receipt_number"
                            type="string128"
                            rules="required"
                            v-model="entry.receipt_number"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("receipts.received_from") }} <span class="app-require">＊</span></label>
                        <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-input
                            name="received_from"
                            type="string128"
                            rules="required"
                            v-model="entry.received_from"
                        />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("receipts.order_id") }} <span class="app-require">＊</span></label>
                        <div class="col-xl-5 col-lg-6 col-sm-6">
                            <div class="row">
                                <div class="col-xl-7 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <app-input
                                        name="order_id"
                                        :disabled="true"
                                        v-model="entry.order_id"
                                        :class="['flex-fill']"
                                        rules="required"
                                    />
                                </div>
                                <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <button
                                        @click="handleOpenOrderListModal()"
                                        class="btn btn-primary w-100"
                                        type="button"
                                        style="height: 40px !important;"
                                    >
                                        {{ $t("receipts.choose_list") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("receipts.note") }}</label>
                        <div class="col-xl-5 col-lg-6 col-sm-6">
                            <app-text
                                name="for_payment_of"
                                type="string1024"
                                v-model="entry.for_payment_of"
                            />
                        </div>
                    </div>

                    <div class="card-footer row justify-content-end gr-button">
                        <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="handlerCancelButton">
                            {{ $t('common.cancel') }}
                        </button>
                        <button type="button" class="btn btn btn-danger m-r-10" @click="handleDeleteConfirm" v-if="idEditing">
                            {{ $t('common.delete') }}
                        </button>
                        <button :disabled="!valid && validated" class="btn btn-primary" type="submit">
                            {{idEditing ?  $t('common.save') :  $t('common.create')}}
                        </button>
                    </div>
                </div>
            </form>
        </validation-observer>
    </div>
</template>
<script>
import {createHmac} from "crypto";
import {__noticeWarning} from "../../../utils";
import AppModalOrder from "@components/_common/popup/AppModalOrder";
import {RECEIPTS_CONSTANT} from "../../../constants/receipts";
import {ENDPOINT} from "../../../constants/api";

export default {
    name: "FormPage",
    components: {
        AppModalOrder,
    },
    data() {
        const id = this.$route.params.id
        return {
            entry: {
                apartment_id: "",
                order_id: "",
                status_enabled : 1
            },
            apiKeyId: null,
            meta: {
                orders: [],
                apartments: [],
            },
            idEditing: id,
            loading : true,
            isLineLink: '',
            endPoint : ENDPOINT.UI_HELPER_RECEIPTS_GET_LIST_ORDER + "?apartment_id=''"
        }
    },

    watch: {
        "entry.apartment_id": async function (value) {
            if (value) {
                await this.getMetaData({apartment_id: value})
                this.endPoint = ENDPOINT.UI_HELPER_RECEIPTS_GET_LIST_ORDER + "?apartment_id=" + value

                // Clear order id if not on list
                let checkInArr = false
                await this.$request.get(this.endPoint).then(res => {
                    if (!res.hasErrors()) {
                        if (res.data.data) {
                            let orderId = this.entry.order_id
                            $.each(res.data.data, function(index, order) {
                                if (order.id == orderId) {
                                    checkInArr = true
                                }
                            });
                        }
                    }
                    if (!checkInArr) {
                        this.entry.order_id = ''
                    }
                })
            } else {
              this.entry.order_id = ''
            }
        },
    },

    async created() {
        if (this.idEditing) {
            this.loading = false;
            await this.$request.get(this.ENDPOINT.RECEIPTS_EDIT(this.idEditing)).then(res => {
                if (res.hasErrors()) {
                  if (res.status === 'error'){
                    this.__noticeError(this.$t(res.data.error_msg));
                  }
                  this.redirectDefaultValue()
                } else {
                  this.entry = res.data.data
                  this.loading = true;
                }
            })
        }
    },

    async mounted() {
        if (!this.idEditing) {
            await this.getMetaData({});
            this.endPoint = ENDPOINT.UI_HELPER_RECEIPTS_GET_LIST_ORDER + "?apartment_id=''"
        }
    },

    methods: {
        getMetaData(params) {
            this.$request.get(this.ENDPOINT.UI_HELPER_RECEIPTS, params).then(res => {
                this.meta = {
                    apartments: res.data.apartments,
                }
                this.meta.apartments.unshift({
                    id: "",
                    name: this.$t('common.choose')
                })
            })
        },

        handlerCancelButton() {
          if (localStorage.getItem('receipt_search_url')) {
            window.location.href = localStorage.getItem('receipt_search_url');
          } else {
            this.__redirectBack()
          }
        },
        async createOrUpdate() {
            let res = null;
            let msg = "";
            let params = _.cloneDeep(this.entry);
            if (this.idEditing) {
                res = await this.$request.patch(this.ENDPOINT.RECEIPTS_EDIT(this.idEditing), params);
                msg = this.$t("common.msg update ok")
            } else {
                res = await this.$request.post(this.ENDPOINT.RECEIPTS_CREATE, params);
                msg = this.$t("common.msg create ok")
            }
            if (res.hasErrors()) {
                this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
                if (res.status === 'error') {
                  await this.redirectDefaultValue()
                }
            } else {
                this.__noticeSuccess(msg);
                await this.redirectDefaultValue()
            }
        },
        handleDeleteConfirm() {
            this.$refs.entry_modal_delete.show();
        },
        async deleteEntry() {
            const res = await this.$request.delete(this.ENDPOINT.RECEIPTS_DELETE(this.idEditing));
            if (!res.hasErrors()) {
                this.__noticeSuccess(this.$t("common.msg delete ok"));
                await this.redirectDefaultValue();
            } else {
                this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
              this.$refs.entry_modal_delete.hide();
                if (res.status === 'error') {
                  await this.redirectDefaultValue()
                }
            }
        },
        async redirectDefaultValue() {
            await this.$router.push({
                name: this.ROUTES.ADMIN.RECEIPTS_LIST, query: {
                    'filters.apartment_id.equal': 'all',
                    'filters.status_enabled.equal' : 'all',
                    'filters.from_date.between' : moment().format('YYYY-MM-DD H:mm:ss'),
                    'filters.to_date.between' : moment().format('YYYY-MM-DD H:mm:ss'),
                }
            });
        },

        handleOpenOrderListModal() {
            this.$refs.modal_list_order.show();
        },
        selectOrder(data) {
            this.entry.order_id = data.row.id
            this.$refs.modal_list_order.hide();
        },
    },
}
</script>
