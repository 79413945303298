<template>
  <app-modal
    ref="modal"
    modal-header-title="注文一覧から選択"
    size="lg"

  >
    <template v-slot:header>
      <div class="modal-header clearfix text-left">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-hidden="true"
          @click="handlerClose()"
        >
          x
        </button>
        <span class="title">注文一覧から選択</span>
      </div>
    </template>
    <template v-slot:container>
      <app-basic-table
        ref="basic_table"
        :table-name="$t('users.user list')"
        :setting-columns="columns"
        :filters.sync="filters"
        :endpoint-list="endPoint"
      >
        <template v-slot:filters>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>{{ filters.order_number.label }}</label>
                <app-input :name="filters.order_number.name" input-style="normal"
                           v-model="filters.order_number.value"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>{{ filters.customer_name.label }}</label>
                <app-input :name="filters.customer_name.name" input-style="normal"
                           v-model="filters.customer_name.value"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>{{ filters.customer_id.label }}</label>
                <app-input :name="filters.customer_id.name" input-style="normal"
                           v-model="filters.customer_id.value"
                />
              </div>
            </div>
          </div>
        </template>
        <template v-slot:body-cell-select="props">
          <td class="app-align-middle text-center app-p-none">
            <button
              class="btn btn-primary"
              @click="handlerSelectOrder(props)"
            >
              {{ $t("common.select_customer") }}
            </button>
          </td>
        </template>
      </app-basic-table>
    </template>
  </app-modal>
</template>

<script>
  import AppModal from "../../../components/_common/popup/AppModal";
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import {ENDPOINT} from "../../../constants/api";

  export default {
    name: "AppModalOrder",
    components: {
      AppModal,
      "app-basic-table": AppBasicTable,
    },
    props : [
      "endPoint",
      "idEditing"
    ],
    methods: {
      hide() {
        this.$refs.modal.hide();
      },

      show() {
        this.$refs.modal.show();
        // Refresh table data
        this.$refs.basic_table.getEntries();
      },

      handlerClose() {
        this.redirectDefaultValue()
      },
      
      handlerSelectOrder(data) {
        this.$emit('selectOrder', data);
        this.redirectDefaultValue()
      },

      async redirectDefaultValue() {
        if (this.idEditing) {
          await this.$router.push({
            name: this.ROUTES.ADMIN.RECEIPTS_EDIT, query: {}
          }).catch(e => {
            console.log('no warning console');
          });
        } else {
          await this.$router.push({
            name: this.ROUTES.ADMIN.RECEIPTS_CREATE, query: {}
          }).catch(e => {
            console.log('no warning console');
          });
        }
      },
    },
    data() {
      return {
        filters: {
          order_number: {
            name: "order_number",
            condition: "like",
            label: this.$t("order_list.order_number")
          },
          customer_name: {
            name: "customer_name",
            condition: "like",
            label: this.$t("order_list.customer_name")
          },
          customer_id: {
            name: "customer_id",
            condition: "equal",
            label: this.$t("receipts.customer_id")
          },
        },
        columns: [
          {name: "id", label: this.$t("common.id"), sortable: true, textAlign: 'text-center'},
          {name: "order_number", label: this.$t("order_list.order_number"), sortable: true},
          {name: "customer_name", label: this.$t("order_list.customer_name"), sortable: true},
          {name: "customer_id", label: this.$t("receipts.customer_id"), textAlign: 'text-center'},
          {name: "select", label: this.$t("common.select_customer"), textAlign: 'text-center'},
        ],
        loading : true
      }
    },
    mounted() {
    },
  }
</script>
